class DataManager {
    constructor() {
        if (DataManager.instance) {
            return DataManager.instance;
        }
        this.data = {
            savedSearchQuery: null,
            savedSearchFilters: null,
            savedSortBy: null,
            filteredResults: [],
            searchQuery: "",
            allSitesPressed: false,
            pageSize: 20,
            pageIndex: 1,
            totalDevices: 0,
            totalPages: 0,
            getAllDevices: 0,
            online: 0, 
            offline: 0, 
            noTag: 0, 
            sort: -1,
            categoryFilterLength: 0,
            sortByLength: 0,
            categoryFilter: [],
            sortBy: [],
        };

        DataManager.instance = this;
        //Object.freeze(DataManager.instance);
    }

    // Method to set data
    setItem(key, value) {
        if (this.data.hasOwnProperty(key)) {
            this.data[key] = value;
        } else {
            console.warn(`Key ${key} is not a valid data property.`);
        }
    }

    // Method to get data
    getItem(key) {
        if (this.data.hasOwnProperty(key)) {
            return this.data[key];
        } else {
            console.warn(`Key ${key} is not a valid data property.`);
            return null;
        }
    }

    // Method to clear specific data
    clearItem(key) {
        if (this.data.hasOwnProperty(key)) {
            this.data[key] = [];
        } else {
            console.warn(`Key ${key} is not a valid data property.`);
        }
    }

    // Method to clear all data
    clearAll() {
        this.data = {
            savedSearchQuery: null,
            savedSearchFilters: null,
            savedSortBy: null,
            filteredResults: [],
            searchQuery: "",
            allSitesPressed: false,
            pageSize: 20,
            pageIndex: 1,
            totalDevices: 0,
            totalPages: 0,
        };
    }
}
;
export default DataManager;

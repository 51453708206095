
import classes from './App.module.css';

import { useSelector, useDispatch } from "react-redux";
import { authActions } from "./store/auth-Slice";
import React, { useEffect } from "react";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import RootLayout from "./Pages/Root/Root/AuthRootLayout";
import SubRootLayout from './Pages/Root/SubRoot/SubRootLayout';
import SearchView, { loader as SearchViewLoader } from "./Pages/SearchView/SearchView";
import Dashboard, { loader as DashboardLoader } from './Pages/Dashboard/Dashboard';
import SiteView, { loader as siteviewLoader } from "./components/Site/Device/SiteView";
import ErrorPage from "./Pages/Error/ErrorPage";
import VerifyPage from "./components/LoggingIn/VerifyAccount/VerifyPage";
import LoginPage from './components/LoggingIn/LoginV2/LoginPage';
import PortalActivityPage, { loader as portalActivityLoader } from './Pages/PortalActivity/PortalActivityPage';
import UserManagementPage, { loader as userManagementPageLoader } from './Pages/User Management/UserManagementPageTableView';
import { uiActions } from './store/ui-slice';
import UserDevicesPage, { loader as userDevicesPageLoader } from './Pages/User Devices/UserDevicesPage';
import UserManagementRoot from './Pages/User Management/UserManagementRoot';
import RegisterPage from './components/LoggingIn/Register/RegisterPage';
import NotificationCard2 from './components/UI/Modal/NotifcationCard/NotificationCard';
import SocketManager from './Socket/useSocketManager';
import UserManagementPageTableView from './Pages/User Management/UserManagementPageTableView';
import UserManagementPageCardGrid from './Pages/User Management/UserManagementPageCardGrid';
import { tokenLoader, checkAuthLoader } from "./auth";
import { loader as logoutAction } from "./Pages/Logout/Logout";
import Button1 from './components/UI/Button/Button1';
import SocketTest from './Socket/SocketTest';
import FirstTest from './FirstTest';
import DeviceActivityPage, {loader as deviceActivityPageLoader} from './Pages/DeviceActivity/DeviceActivityPage';

const AuthRoute = {

  path: '/',
  element: <RootLayout/>,
  errorElement: <ErrorPage/>,
  loader: tokenLoader,
  children: [
    {
      index: true,
      element: <LoginPage/>
    },
    {
      path: 'Verify',
      element: <VerifyPage/>,
      loader: checkAuthLoader,
    },
    {
      path: 'Register',
      element: <RegisterPage />
    },
    {
      path: '/Dashboard',
      element: <SubRootLayout />,
      loader: checkAuthLoader,
      children: [
        {
          index: true,
          element: <Dashboard />,
          loader: DashboardLoader
        },
        {
          path: 'SearchView',
          element: <SearchView />,
          loader: SearchViewLoader
        },
        {
          path: 'SiteView/:SiteID',
          element: <SiteView />,
          loader: siteviewLoader
        },
        {
          path: 'Users',
          element: <UserManagementRoot />,
          children: [
            {
              index: true,
              element: <UserManagementPageCardGrid />,
              loader: userManagementPageLoader
            },
            {
              path: 'UserSites',
              element: <UserDevicesPage />,
              loader: userDevicesPageLoader
            },
          ]
        },
        {
          path: 'Activity',
          element: <PortalActivityPage />,
          loader: portalActivityLoader
        },
        {
          path: 'SiteActivity',
          element: <DeviceActivityPage />,
          loader: deviceActivityPageLoader
        },
      ]
    },
    {
      path: '/logout',
      loader: logoutAction
    }
  ],
}

const router = createBrowserRouter([
  AuthRoute
]);

const App = (props) => {

  let singleDevicePins = useSelector(state => state.service.singleDevicePins);
  let isSocketConnected = useSelector(state => state.service.isSocketConnected);

  const token = useSelector(state => state.auth.token);
  const refreshToken = useSelector(state => state.auth.refreshToken);
  const dispatch = useDispatch();

  //--- save local variables to session storage before refresh then save session variable to local storage after refresh (then delete session storage)
  // useEffect(() => {
  //     let isUserAction = true;

  //     const handleBeforeUnload = () => {
  //       if (isUserAction) {
  //         // dispatch(authActions.onBeforeRefresh());
  //       } else {
  //         isUserAction = false;
  //       }
  //     };

  //     const handleLoad = () => {
  //       // dispatch(authActions.onAfterRefresh());
  //     };

  //     window.addEventListener('beforeunload', handleBeforeUnload);
  //     window.addEventListener('load', handleLoad);

  //     return () => {
  //       window.removeEventListener('beforeunload', handleBeforeUnload);
  //       window.removeEventListener('load', handleLoad);
  //     };
  //   }, []);
  // ------------------------------------------------------------------

  // const HandleUpdateToken = () => {
  //   const newApiData = {
  //     newToken: "e",
  //     newRefreshToken: sessionStorage.getItem("refreshToken")
  //   }
  //   dispatch(
  //     authActions.testUpdateToken({
  //       newApiData
  //     }));
  //     sessionStorage.setItem("token", "e");
  // }



  

  return (
    <React.Fragment>
      {/* <Button1 onClick={HandleUpdateToken}>Update Token</Button1> */}
      <RouterProvider router={router} />

    </React.Fragment >
  );
}

export default App;



import { createSlice } from "@reduxjs/toolkit";
// const user = user // user will be the current user stored in the session brwser else null
//     ? { isLoggedIn: true, userFromSession }
//     : { isLoggedIn: false, user: null };

// const userFromSession = sessionStorage.getItem('fullname');

import DataManager from "../hooks/DataManager";

const dataManager = new DataManager()

const initialState = {
    firstName: sessionStorage.getItem('FirstName'),
    email: null,


    initialLogIn: false,
    verify2Factor: false,
    isLoggedIn: false,

    token: sessionStorage.getItem('token') || null,
    client: sessionStorage.getItem('client') || null,
    refreshToken: sessionStorage.getItem('refreshToken') || null,
    userName: sessionStorage.getItem('userName') || null,
    userID: sessionStorage.getItem('userID') || null,
    companyName: sessionStorage.getItem('companyName') || null,
    role: sessionStorage.getItem('role') || null,
    DeviceUUID: sessionStorage.getItem('DeviceUUID') || null,
    groups: sessionStorage.getItem('groups') || null,
    getAllDevices: sessionStorage.getItem('getAllDevices') || null,

    ClickedUserID: sessionStorage.getItem('ClickedUserID') || null,
    ClickedUserFullName: sessionStorage.getItem('ClickedUserFullName') || null
}



const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        UpdateToken: (state, action) => {

            console.log(action.payload);
            const { token, refreshToken } = action.payload;
            state.token = token;
            state.refreshToken = refreshToken;
        },
        testUpdateToken: (state, action) => {

            console.log(action.payload);
            const { newToken } = action.payload.newApiData;
            console.log(newToken);
            // console.log(newRefreshToken);
            state.token = newToken;
            // state.refreshToken = newRefreshToken
        },
        Login: (state, action) => {

            const { token, client, refreshToken, Email, groups, getAllDevices } = action.payload;
            console.log(groups);

            state.token = token;
            state.client = client;
            state.refreshToken = refreshToken;
            state.email = Email;
            state.groups = JSON.stringify(groups);
            state.getAllDevices = getAllDevices;
            

            sessionStorage.setItem('token', token);
            sessionStorage.setItem('refreshToken', refreshToken);
            sessionStorage.setItem('client', client);
            sessionStorage.setItem('email', Email);
            sessionStorage.setItem('groups', JSON.stringify(groups));
            sessionStorage.setItem('getAllDevices', getAllDevices);
            const expiration = new Date();
            expiration.setHours(expiration.getHours() + 1);
            sessionStorage.setItem('expiration', expiration.toISOString());

            state.initialLogIn = true;
        },
        twoFactorVerify: (state, action) => {

            const { userName, userID, companyName, role } = action.payload;
            state.userName = userName
            const UserIdTdInteger = parseInt(userID);


            state.userID = UserIdTdInteger;
            state.companyName = companyName
            state.role = role

            const nameParts = userName.split(' ');
            const firstName = nameParts[0];
            state.firstName = firstName;



            sessionStorage.setItem('userName', userName);
            sessionStorage.setItem('FirstName', firstName);
            sessionStorage.setItem('userID', UserIdTdInteger);
            sessionStorage.setItem('companyName', companyName);
            sessionStorage.setItem('role', role);

            state.verify2Factor = true;
            if (state.initialLogIn === true && state.verify2Factor === true) {
                state.isLoggedIn = true;
            }
        },
        Logout: (state) => {


            state.isLoggedIn = false;

            if (state.isLoggedIn === false) {
                state.initialLogIn = false;
                state.verify2Factor = false;

                state.token = null;
                state.refreshToken = null;
                state.client = null;
                state.userName = null;
                state.userID = null;
                state.companyName = null;
                state.role = null;
                state.email = null;

                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('client');
                sessionStorage.removeItem('userName');
                sessionStorage.removeItem('userID',);
                sessionStorage.removeItem('companyName');
                sessionStorage.removeItem('role');
                sessionStorage.removeItem('DeviceID');
                sessionStorage.removeItem('DeviceUUID');
                sessionStorage.removeItem('ClickedUserFullName');
                sessionStorage.removeItem('FirstName');
                sessionStorage.removeItem('DeviceName');
                sessionStorage.removeItem('email');
                sessionStorage.removeItem('ClickedUserID');
                sessionStorage.removeItem('getAllDevices');
                sessionStorage.removeItem("SavedSearchSortOrder");
                sessionStorage.removeItem("groups");
                sessionStorage.removeItem("IsGetAllDevicesPressed");
                dataManager.clearAll()

            }
        },
        authSessionCheck: (state) => {

            const token = sessionStorage.getItem('token');
            const refreshToken = sessionStorage.getItem('refreshToken');
            const client = sessionStorage.getItem('client');
            const userName = sessionStorage.getItem('userName');
            const userID = sessionStorage.getItem('userID');

            const initialLogInPassed = (token != null && refreshToken != null && client != null);
            const stayLoggedIn = (token != null && refreshToken != null && client != null && userName != null && userID != null);
            // When passed initial log in screen, on refresh will bring the user to the verification page.
            if (initialLogInPassed) {

                state.token = sessionStorage.getItem('token');
                state.refreshToken = sessionStorage.getItem('refreshToken');
                state.client = sessionStorage.getItem('client');
                state.email = sessionStorage.getItem('email');
                state.userID = sessionStorage.getItem('userID');
                state.initialLogIn = true;
            }

            // if the user had logged in and refreshes the page, they will stay signed in.
            if (stayLoggedIn) {
                state.token = sessionStorage.getItem('token');
                state.refreshToken = sessionStorage.getItem('refreshToken');
                state.client = sessionStorage.getItem('client');
                state.userName = sessionStorage.getItem('userName');
                state.firstName = sessionStorage.getItem('FirstName');
                state.userID = sessionStorage.getItem('userID');
                state.companyName = sessionStorage.getItem('companyName');
                state.role = sessionStorage.getItem('role');
                state.email = sessionStorage.getItem('email');

                state.isLoggedIn = true;
            } else {
                state.isLoggedIn = false;

                state.token = null;
                state.refreshToken = null;
                state.client = null;
                state.userName = null;
                state.userID = null;
                state.firstName = null;
                state.companyName = null;
                state.role = null;
                state.email = null;
            }
        },
        updateUserStatus: (state, action) => {
            state.role = action.payload;
        },
        setClickedUserDetails: (state, action) => {

            const { UserID, fullName } = action.payload

            state.ClickedUserID = UserID;
            state.ClickedUserFullName = fullName;
        },
        onBeforeRefresh: (state, action) => {
            sessionStorage.setItem('token', state.token);
            sessionStorage.setItem('refreshToken', state.refreshToken);
            sessionStorage.setItem('client', state.client);
            sessionStorage.setItem('userName', state.userName);
            sessionStorage.setItem('FirstName', state.firstName);
            sessionStorage.setItem('userID', state.userID);
            sessionStorage.setItem('companyName', state.companyName);
            sessionStorage.setItem('role', state.role);
            sessionStorage.setItem('email', state.email);
            sessionStorage.setItem('DeviceUUID', state.DeviceUUID);
            sessionStorage.setItem('ClickedUserID', state.ClickedUserID);


            // Remove save Search query & filters for Dashbaord Power Search before refresh
            sessionStorage.removeItem('SavedSearchQuery')
            sessionStorage.removeItem('SavedSearchFilters')
        },
        onAfterRefresh: (state, action) => {
            // state.token = sessionStorage.getItem('token');
            // state.refreshToken = sessionStorage.getItem('refreshToken');
            // state.client = sessionStorage.getItem('client');
            state.userName = sessionStorage.getItem('userName');
            state.firstName = sessionStorage.getItem('FirstName');
            state.userID = sessionStorage.getItem('userID');
            state.companyName = sessionStorage.getItem('companyName');
            state.role = sessionStorage.getItem('role');
            state.email = sessionStorage.getItem('email');
            state.DeviceUUID = sessionStorage.getItem('DeviceUUID');
            state.ClickedUserID = sessionStorage.getItem('ClickedUserID');

            // sessionStorage.removeItem('token');
            // sessionStorage.removeItem('refreshToken');
            // sessionStorage.removeItem('client');
            // sessionStorage.removeItem('userName');
            // sessionStorage.removeItem('FirstName');
            // sessionStorage.removeItem('userID',);
            // sessionStorage.removeItem('companyName');
            // sessionStorage.removeItem('role');
            // sessionStorage.removeItem('email');

            // sessionStorage.removeItem('DeviceID');
            // sessionStorage.removeItem('DeviceName');
            sessionStorage.removeItem('DeviceUUID');
            sessionStorage.removeItem('ClickedUserID');
        },
        updateDeviceUUID: (state, action) => {
            state.DeviceUUID = action.payload;
        }
    }
})

export const authActions = authSlice.actions;

export default authSlice.reducer;


import React, { Suspense, useEffect, useMemo, useState } from 'react'
import classes from "./Logs.module.css";
import TotalEvents from "../../UI/Cards/Logs Cards/TotalEvents/TotalEvents";
import Dropdown from '../../UI/Cards/Logs Cards/Dropdown/Dropdown';
import LinegraphPeaked from '../../UI/Graphs/linegraphPeaked';
import Export from '../../UI/Cards/Logs Cards/Export/Export';
import { Await, useLoaderData } from 'react-router-dom';
import useGetEventsMonth from '../../hooks/UseGetEventsMonth';
import LogsTableLayout from '../../Table/LogsTableLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceEventLogs } from '../../../Api Methods/Api';
import UseGetDateEvents from '../../hooks/UseGetDateEvents';
import UseAddDateSuffix from '../../hooks/useAddDateSuffix';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import ComponentCard from '../../UI/Cards/ComponentCard/ComponentCard';
import CustomDropDown from "../../CustomDropDown/CustomDropDownWithIcon";
import DropdownSelect from '../../CustomDropDown/Input/DropdownSelect';
import SearchbarSmall from '../../UI/Inputs/SearchbarSmall';
import Button1 from '../../UI/Button/Button1';
import UseGetEventsForMonth from '../../hooks/UseGetEventsForMonth';
import DatePickerModal from './DatePickerModal';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';


import { fillMissingDates, formatEvents, getLastSixMonths, FormateEventArrayWithGapObjects, AddGapObjects } from './LogsFunctions';

const Logs = ({ SiteEventLogs, apiData }) => {

  const [EventsData, setEventsData] = useState(SiteEventLogs);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [modalTitle, setModalTitle] = useState("Select a date range to view")
  const [modalComponent, setModalComponent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
    
  useEffect(() => {
    const HandleDeviceEventsList = async () => {
      console.log("here");
      const data = await getDeviceEventLogs(apiData, DeviceUUID, dispatch);

      
      console.log(data)
      setEventsData(data);
    }

    // Set up a timer to fetch data every 20 sec
    const timer1 = setInterval(() => {
      HandleDeviceEventsList();
    }, 60000);

    return () => clearInterval(timer1);
  }, []);
  
  console.count("Logs");

  const [allEvents, setAllEvents] = useState(EventsData)

  useEffect(() => {
    setAllEvents(EventsData);
  }, [EventsData])


  const HandleShowModal = () => {
    setShowModal(true);

    setModalComponent(
      <DateRangePicker
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            isOutsideRange={() => false}
            numberOfMonths={1}
        />
    )
  }

  const HandleCloseModal = () => {
    setShowModal(false);
  }

  // get current date (in ISO 8601 format)
  const currentDate = new Date();
  console.log(currentDate)
  // get current month (int)
  const currentMonth = currentDate.getMonth();
  console.log(currentMonth)

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
        fetchEventsForDateRange(start, end);
    }
};

const fetchEventsForDateRange = (start, end) => {
  // Call your API or filter your events based on the start and end dates
  console.log(`Fetching events from ${start} to ${end}`);
  // Your existing function to fetch or filter events
};


  //---------------------------------------------------------------
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  //creates array of last six months
  const lastSixMonths = useMemo(() => {
    console.count("re-render: getLastSixMonths");
    return getLastSixMonths(currentMonth);
  }, []);


  const [selectedMonthIndex, setSelectedMonthIndex] = useState(currentMonth);
  const [selectedMonth, setSelectedMonth] = useState(months[currentMonth]);

  //---------------------------------------------------------------

  // will be the new useGetEventsMonth Function once logic is moved over.
  const ddata = formatEvents(EventsData, selectedMonthIndex);


  //console.log(allEvents)
  //console.log(selectedMonthIndex)
  let MonthEvents = useGetEventsMonth(allEvents, selectedMonthIndex);

  //console.log(MonthEvents);

  //incase the month has no events then add in one object.
  //---------------------------------------------------------------
  if (MonthEvents.length === 0) {
    MonthEvents = [
      {
        date: "1th " + selectedMonth,
        events: 1
      }
    ]
  }
  //---------------------------------------------------------------

  // Call the function to fill missing dates
  const filledDates = useMemo(() => {
    //console.count("re-render: fillMissingDates + Adding Gap Objects");
    //console.log(selectedMonth)
    const tempfilledDates = fillMissingDates(MonthEvents, selectedMonth);

    return AddGapObjects(tempfilledDates);
  }, [MonthEvents, selectedMonth]);

  console.log(filledDates);

  //-------- Remove the year from each lable  --------------------------------------------------------------
  // Loop through the array
  for (let i = 0; i < filledDates.length; i++) {
    // Split the date string by spaces
    const parts = filledDates[i].date.split(' ');

    // Rejoin the first two parts (day and month) with a space
    filledDates[i].date = parts.slice(0, 2).join(' ');
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // Function to calculate total events for all dates
  function calculateTotalEvents(datesArray) {
    //console.log(datesArray)
    let totalEvents = 0;
    for (let dateObj of datesArray) {
      //console.log(dateObj)
      totalEvents += dateObj.events;
    }
    //console.log(totalEvents)
    return totalEvents;
  }

  // Function to find the object with the highest count (events)
  function findHighestEventCount(datesArray) {
    let highestEventObject = null;
    let highestEventCount = -1;

    for (let dateObj of datesArray) {
      if (dateObj.events > highestEventCount) {
        highestEventObject = dateObj;
        highestEventCount = dateObj.events;
      }
    }

    return highestEventObject.events;
  }

  const highestEvent = findHighestEventCount(filledDates)

  // Call the function to calculate total events
  let totalEvents = calculateTotalEvents(filledDates);


  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const DeviceUUID = sessionStorage.getItem('DeviceUUID');
  sessionStorage.setItem('DeviceUUID', DeviceUUID);


  const [latestDataPoint, setLatestDataPoint] = useState();
  const [prevLatestDataPoint, setPrevLatestDataPoint] = useState();
  const [currentDataPoint, setcurrentDataPoint] = useState();
  const [prevSelectedMonth, setPrevSelectedMonth] = useState()

  const [prevDataPoint, setPrevDataPoint] = useState();
  const [prevSelectedMonthIndex, setPrevSelectedMonthIndex] = useState()
  const [monthEventsfiltering, setMonthEventsFiltering] = useState('');

  const [tableData, setTableData] = useState();
  const [isloading, setIsLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  // Callback function to handle Month change
  const handleSelectChange = (Month, MonthIndex) => {
    console.log(Month)

    //Temp - monthIndex is not being set correctly
    for (const x of months){
      console.log(x)
      if (Month === x){
        const index = months.indexOf(x)
        setSelectedMonthIndex(index)
      }
    }

    console.log(MonthIndex);
    setPrevSelectedMonthIndex(selectedMonthIndex);
    //setSelectedMonthIndex(MonthIndex);
    setSelectedMonth(Month);
    setLatestDataPoint(latestDataPoint);
    setcurrentDataPoint(latestDataPoint);
  };


  // Finds and shows the most recent date of the month's event in the event table
  //------------------------------------------------------------------
  useEffect(() => {
    if (latestDataPoint != undefined) {
      setPrevLatestDataPoint(latestDataPoint);
    }
    if (prevLatestDataPoint != latestDataPoint) {
      setcurrentDataPoint(parseInt(MonthEvents[0].date))
    } else {
      setLatestDataPoint(parseInt(MonthEvents[0].date));
    }
  }, [selectedMonthIndex, filledDates]);
  //------------------------------------------------------------------

  useEffect(() => {
    // when the user switches months, update the table || for checking if user is viewing the most recent data point - if so then update table with new events
    if ((currentDataPoint == latestDataPoint) && (monthEventsfiltering == "")) {
      let lastIndex = -1; // Initialize with -1 to indicate no element found
      for (let i = 0; i < filledDates.length; i++) {
        if (filledDates[i].events > 0 && filledDates[i].date !== '') {
          lastIndex = i; // Update lastIndex when condition met
        }
      }

      HandleDateClicked((lastIndex / 2) + 1);
    } else if ((currentDataPoint != latestDataPoint) && (monthEventsfiltering == "")) {
      HandleDateClicked(currentDataPoint);
    }
  }, [selectedMonthIndex, EventsData, currentDataPoint, latestDataPoint, prevSelectedMonth, monthEventsfiltering])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      //   Perform fetch call with the input value
      if (monthEventsfiltering != "") {
        setTableData(UseGetEventsForMonth(EventsData, selectedMonthIndex));
      } else {
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [monthEventsfiltering, selectedMonthIndex])

  const HandleDateClicked = async (DataPointIndex) => {

    const DateEvents = UseGetDateEvents(EventsData, DataPointIndex, selectedMonthIndex);
    setTableData(DateEvents);
    setPrevDataPoint(currentDataPoint);
    setcurrentDataPoint(DataPointIndex);
    setIsLoading(false);
  }

  const toggleDropdown = () => setIsOpen(!isOpen);


  return (
  <>
    <div>
        <DatePickerModal show={showModal} onClose={HandleCloseModal} title={modalTitle} onSelect={handleDateChange}>
          {modalComponent}
        </DatePickerModal>
    </div>

  <div>
    <ComponentCard className={classes["table-container"]}>
      <div className={classes["logs-container"]} >
        <div className={classes.top} >
          <TotalEvents totalEvents={totalEvents} />
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <DropdownSelect
              options={lastSixMonths}
              selectedOption={selectedMonthIndex}
              onSelect={handleSelectChange}
              placeholder={"Select Month"}
            />


             {/* <div className="date-range-picker-dropdown"> TODO
            <Button1 onClick={HandleShowModal} className="dropdown-toggle">
                Select Date Range
            </Button1> */}
        {/* </div> */}

            <SearchbarSmall
              type="text"
              placeholder={"Search events for " + months[selectedMonthIndex] + "..."}
              value={monthEventsfiltering}
              onChange={(e) => setMonthEventsFiltering(e.target.value)}
              onClear={() => setMonthEventsFiltering("")}
            />
          </div>
        </div>
        <LinegraphPeaked events={filledDates} HandleDateClicked={HandleDateClicked} setIsLoading={setIsLoading} highestEvent={highestEvent} latestDataPoint={latestDataPoint} />
        {/* <Button1 onClick={addEvent}>Add Event</Button1>
         <Button1 onClick={removeEvent}>Remove Event</Button1>  */}
      </div>

      < Suspense fallback={
        < div className={classes["loadingSpinner-container"]} >
          <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
        </div >
      }>
        <Await resolve={tableData}>
          {(loadedTableData) =>
            <LogsTableLayout data={loadedTableData} isloading={isloading} Filtering={monthEventsfiltering} />
          }
        </Await>
      </Suspense >
    </ComponentCard>
    </div>
    </>
  )
}

export default Logs;

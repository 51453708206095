import React, { useState, useEffect, Suspense } from 'react'

import classes from "./DeviceManager.module.css";

import FilterSearch from "../PowerSearch/PowerSearch";
import DeviceLoadingGrid from '../GridList/DeviceLoadingGrid';
import DevicesGrid from '../GridList/DevicesGrid';
import { Await } from 'react-router-dom';
import CategoryFilter from '../PowerSearch/CategoryFilter';
import SortDevices from '../PowerSearch/SortDevices';
import GetAllDevices from '../../Pages/Dashboard/GetAllDevices';
import ExportExcel from '../ExcelExport/ExcelExport';
import UseDateFormat from '../hooks/UseDateFormat';
import PowerSearch from '../PowerSearch/PowerSearch';
import GetPagedDevices from '../../Pages/Dashboard/GetPagedDevices';
import GetAllPagedDevices from '../../Pages/Dashboard/GetAllPagedDevices';
import { useSelector } from 'react-redux';
import DataManager from '../../hooks/DataManager';

const DeviceManager = ({ client, token, companyName, aiQuery, getAllDevices }) => {
    const dataManager = new DataManager();

    let [filteredResults, setFilteredResults] = useState([]);
    let [maxPagedFetches, setMaxPagedFetches] = useState(2);
    getAllDevices = 1;
    dataManager.setItem('getAllDevices', getAllDevices);
    const groups = useSelector(state => state.auth.groups);
    const parsedGroups = JSON.parse(groups);
    const groupBreadCrumb = parsedGroups.find((group) => group.name === companyName)
    const groupPath = groupBreadCrumb.group_path
    const GroupNamesArray = groupPath.split(">");
    const hasCachedSites = dataManager.getItem('filteredResults')?.length > 0
    const [isloading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState(dataManager.getItem('searchQuery'));
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [sortBy, setSortBy] = useState('all')
    const [isGetAllDevicesPressed, setIsGetAllDevicesPressed] = useState(dataManager.getItem('allSitesPressed'));
    const [pageFetchedIndex, setPageFetchedIndex] = useState(dataManager.getItem('pageIndex'));
    const [pageSize, setPageSize] = useState(dataManager.getItem('pageSize'));
    const [totalDevices, setTotalDevices] = useState(0);
    const [totalPages, setTotalPages] = useState(null);

    const handleFilterLogic = async (data, searchQuery, selectedCategories, sortBy) => {
        function hasDevicesKey(data) {
            return Array.isArray(data) && data.every(entry => entry.hasOwnProperty('devices'));
        }

        let flattenedDataDevices;

        if (hasDevicesKey(data)) {
            flattenedDataDevices = data.flatMap(entry => entry.devices);
            console.log("here")
        } else {
            flattenedDataDevices = data
            console.log("here")
        }

        let sortedData = flattenedDataDevices;
        let preFilteredData;
        console.log(sortedData)
        sortedData != undefined ? preFilteredData = sortedData : preFilteredData = data;

        let flattenedData;

        if (hasDevicesKey(preFilteredData)) {
            flattenedData = preFilteredData.flatMap(entry => entry.devices);
        } else {
            flattenedData = preFilteredData
        }

        setFilteredResults(flattenedData);
    };
    //---------------------------------------------------------------------------------------------------------------------

    //  ------------ used to populate the search if users uses breadcrumb to go back to Dashboard ------------------
    useEffect(() => {
        console.log(dataManager.getItem('filteredResults'));
        //const SaveSearchFilters = JSON.parse(sessionStorage.getItem('SavedSearchFilters'));
        //const SavedSortBy = JSON.parse(sessionStorage.getItem("SavedSearchSortOrder"))
        setFilteredResults(dataManager.getItem('filteredResults'));
        setPageFetchedIndex(dataManager.getItem('pageIndex'));
        setTotalDevices(dataManager.getItem('totalDevices'));
        setTotalPages(dataManager.getItem('totalPages'));

        // if (searchQuery != "" && searchQuery != " ") {
        //     SaveSearchFilters != null && setCategoryFilter(SaveSearchFilters);
        //     SavedSortBy != null && setSortBy(SavedSortBy);
        // }

        // if (!isGetAllDevicesPressed) {
        //     dataManager.clearItem('filteredResults');
        // }

    }, [aiQuery])

    const HandleSaveSearchQuery = () => {
        sessionStorage.setItem('SavedSearchFilters', JSON.stringify(categoryFilter));
        sessionStorage.setItem("SavedSearchSortOrder", JSON.stringify(sortBy));
        dataManager.setItem('filteredResults', filteredResults);
        dataManager.setItem('totalPages', totalPages);
        dataManager.setItem('totalDevices', totalDevices);
    }

    useEffect(() => {
        const SaveSearchFilters = JSON.parse(sessionStorage.getItem('SavedSearchFilters'));
        const SavedSortBy = JSON.parse(sessionStorage.getItem("SavedSearchSortOrder"))
        SaveSearchFilters != null && setCategoryFilter(SaveSearchFilters);
        SavedSortBy != null && setSortBy(SavedSortBy);

        console.log(getAllDevices)
        console.log(hasCachedSites)
        if (getAllDevices == 1) {
            if (!hasCachedSites) {
                setIsGetAllDevicesPressed(true);
                dataManager.setItem('allSitesPressed', true);
            }
        }
    }, [getAllDevices])

    useEffect(() => {
        dataManager.setItem('filteredResults', filteredResults)
    }, [filteredResults])

    const exportData = filteredResults?.map((site) => {
        console.log("This has" + site);

        return {
            "Tag": site.tag,
            "UIPIOID": site.key,
            "Serial Number": site.subGroup,
            "IP Address": site.ip,
            "Firmware": site.firmware,
            "Status": site.status,
            "Initial Activation": UseDateFormat(site.created),
            "Owned by": site.name,
        }
    })

    useEffect(() => {
        if (dataManager.getItem('allSitesPressed')) {
            console.log(dataManager.getItem('allSitesPressed'));
            <GetAllPagedDevices
                searchQuery={searchQuery}
                setIsLoading={setIsLoading}
                handleFilterLogic={handleFilterLogic}
                categoryFilter={categoryFilter}
                sortBy={sortBy}
                setSearchQuery={setSearchQuery}
                setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                isGetAllDevicesPressed={isGetAllDevicesPressed}
                filteredResults={filteredResults}
                setFilteredResults={setFilteredResults}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageFetchedIndex}
                setPageNumber={setPageFetchedIndex}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                setTotalDevices={setTotalDevices}
                totalDevices={totalDevices}
                getAllDevices={getAllDevices}
            />;
        }
    }, [pageFetchedIndex])

    return (
        <div className={classes.DeviceManagerPage}>
            <div className={classes.searchContainer}>
                {/* Search Bar */}
                <PowerSearch
                    placeholder={"Search Site..."}
                    searchQuery={searchQuery}
                    categoryFilter={categoryFilter}
                    setSearchQuery={setSearchQuery}
                    setIsLoading={setIsLoading}
                    setFilteredResults={setFilteredResults}
                    handleFilterLogic={handleFilterLogic}
                    categories={["Online", "Offline", "NoTag", "Favorited", "Not Favorited"]}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    filteredResults={filteredResults}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    hasCachedSites={hasCachedSites}
                    pageSize={pageSize}
                    pageFetchedIndex={pageFetchedIndex}
                    setTotalPages={setTotalPages}
                    setTotalDevices={setTotalDevices}
                />

                {/* Excel Button */}
                {
                    filteredResults?.length > 0 && < ExportExcel
                        excelData={exportData}
                        fileName={"Sites Filtered by:" + searchQuery}
                        title={"IPIO Filtered Sites: " + searchQuery}
                        colWidths={
                            [
                                { wch: 20 },
                                { wch: 20 },
                                { wch: 22 },
                                { wch: 13 },
                                { wch: 10 },
                                { wch: 10 },
                                { wch: 20 },
                                { wch: 20 },
                            ]
                        }
                        style={{ height: "50px", fontSize: "16px" }}
                    />
                }

                {/* Filters Button */}
                <div className={classes.filters}>
                    {categoryFilter && <CategoryFilter setCategoryFilter={setCategoryFilter} categories={["Online", "Offline", "No Tag"]} categoryFilter={categoryFilter} />}
                </div>

                {/* Sort By Button */}
                <SortDevices
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    categories={["Tag - Asc", "Tag - Desc", "Firmware - Asc", "Firmware - Desc", "User Count - Asc", "User Count - Desc"]}
                />

                {/* All Devices Button */}
                {/* <GetAllDevices
                    searchQuery={searchQuery}
                    setIsLoading={setIsLoading}
                    handleFilterLogic={handleFilterLogic}
                    setFilteredResults={setFilteredResults}
                    categoryFilter={categoryFilter}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    setSearchQuery={setSearchQuery}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    filteredResultsLength={filteredResults?.length}
                    
                    
                    hasCachedSites={hasCachedSites}
                /> */}

                <GetAllPagedDevices
                    searchQuery={searchQuery}
                    setIsLoading={setIsLoading}
                    handleFilterLogic={handleFilterLogic}
                    categoryFilter={categoryFilter}
                    sortBy={sortBy}
                    setSearchQuery={setSearchQuery}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    filteredResults={filteredResults}
                    setTotalPages={setTotalPages}
                    setFilteredResults={setFilteredResults}
                    setMaxPagedFetches={setMaxPagedFetches}
                    maxPagedFetches={maxPagedFetches}
                    pageFetchedIndex={pageFetchedIndex}
                    setPageFetchedIndex={setPageFetchedIndex}
                    setTotalDevices={setTotalDevices}
                    totalDevices={totalDevices}
                    getAllDevices={getAllDevices}
                />
            </div>

            {console.log(pageFetchedIndex)}

            <Suspense fallback={
                <div style={{ display: "flex", width: "100%" }}>
                    <DeviceLoadingGrid />
                </div>
            }>
                <Await resolve={filteredResults}>
                    {(loadedFilteredResults) =>
                        // <GridList2 data={loadedSearchResults} />
                        <div style={{ display: "flex", width: "100%" }}>
                            {isloading ?
                                <div style={{ display: "flex", width: "100%" }}>
                                    <DeviceLoadingGrid CardCount={searchQuery?.length == 2 ? 10 : searchQuery?.length >= 3 && 1} />
                                </div> :

                                searchQuery != "" || searchQuery != " " || isGetAllDevicesPressed ?
                                    loadedFilteredResults && loadedFilteredResults?.length !== 0 ?
                                        <DevicesGrid data={loadedFilteredResults} HandleSaveSearchQuery={HandleSaveSearchQuery} totalPages={totalPages} setTotalPages={setTotalPages} pageFetchedIndex={pageFetchedIndex} setPageFetchedIndex={setPageFetchedIndex} pageSize={pageSize} setPageSize={setPageSize} totalDevices={totalDevices} setTotalDevices={setTotalDevices} /> :
                                        "No sites found" :
                                    ""
                            }
                        </div >
                    }
                </Await>
            </Suspense>
        </div>
    )
}

export default DeviceManager
import React from 'react'

import classes from './OutputPinCircle.module.css'

import outputRed from '../../../assets/stateIcons/output_red.png'
import outputGreen from '../../../assets/stateIcons/output_green.png'
import outputGrey from '../../../assets/stateIcons/pin_disabled.png'
import pulse from '../../../assets/stateIcons/pulse.png'
import inputOn from '../../../assets/stateIcons/input_blue.png'
import inputOff from '../../../assets/stateIcons/input_grey.png'

const OutputPinCircle = ({ OutputPins }) => {
    return (
        OutputPins.map((OutputPin, index) => {
            let OutputPinImage;

            console.log(OutputPin)

            if (OutputPin.type == "PULSE" || OutputPin.type == "VIRTUAL_PULSE") {
                OutputPinImage = `url(${pulse})`
            } else {
                if (OutputPin.pinState === 0) {
                    OutputPinImage = `url(${outputGreen})`;
                } else if (OutputPin.pinState === 1) {
                    OutputPinImage = `url(${outputRed})`;
                } else if (OutputPin.pinState === 2) {
                    OutputPinImage = `url(${outputGrey})`; // Assuming you have a grey image
                }
            }

            return (
                <li key={index}>
                    <div
                        className={classes.output}
                        style={{
                            backgroundImage: OutputPin.active ? OutputPinImage : `url(${outputGrey})`,
                            opacity: OutputPin.active ? 1.0 : 0.25,
                            backgroundSize: 'cover', // Ensure the image covers the entire div
                            backgroundPosition: 'center', // Center the image
                            backgroundColor: !OutputPin.active ? "lightgrey" : "transparent" // Fallback color
                        }}
                    />
                </li>
            )
        })
    );
}

export default OutputPinCircle


/*
const OutputPinCircle = ({ outputs, OutputPins }) => {
// const { pinName, pinStatusOff, pinStatusOn, pinState, icon, DeviceUUID, Pin, active } ;     

    return (
        OutputPins.map((OutputPin, index) => (
            <li key={index}>
                <div className={classes.output}
                    style={{ background: OutputPin.active ? OutputPin.pinState ? "red" : "green" : "lightgrey" }}
                />
            </li>
        )
        ))
}

export default OutputPinCircle

*/
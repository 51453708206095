import React, { Children, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangeoutputPinState } from "../../../../../../store/service-actions"
import Card from "../../../../Cards/Card";

import classes from "./EditPinOutput.module.css";
import InactiveIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/default - Off.svg";
import HiddenIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/HiddenPin.svg";
import SettingCogIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Admin - Settings.svg"


const EditPinOutput = ({ OutPutPin, polarity, HandleShowEditPinForm, setPinDetails }) => {
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);

    const { pinName, pinStatusOff, pinStatusOn, pinState, icon, DeviceUUID, Pin, active, notificationActive, type } = OutPutPin
    console.log(OutPutPin);

    const image = icon + " - Off";
    const otherStateImage = icon + " - On";
    const staticPath = "/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/";

    const imageFromPath = `${process.env.PUBLIC_URL}${staticPath}${image}.svg`
    let otherStateImageFromPath = `${process.env.PUBLIC_URL}${staticPath}${otherStateImage}.svg`;

    const initialPinState = polarity === 1 ? !pinState : pinState;

    const [PinState, setPinState] = useState(initialPinState);
    const [pinStateLoading, setPinStateLoading] = useState(false);

    useEffect(() => {
        //if external source updates the state, update PinState to reflect that
        setPinState(pinState);
    }, [pinState]);

    return (
        <Card className={classes["pin-switch"]} onClick={() => {HandleShowEditPinForm(); setPinDetails(OutPutPin)}}>
            <div className={classes.top}>
                <div className={classes["top-left"]}>
                    <h3 className={classes.lable}>{pinName}</h3>
                    <h3 className={` ${classes.lable}  ${active ? classes.active : classes.inactive}`}>
                        {active ? "Active" : "Inactive"}
                    </h3>
                </div>
                <img className={classes.img} src={SettingCogIcon} />
            </div>

            <div className={classes.bottom}>
                <div style={{ backgroundColor: active ? `#168AAD` : "grey", borderRadius: "50%", width: "12px", height: "12px" }} />
            </div>
        </Card>
    );
}

export default EditPinOutput;
import React from 'react'
import postRequest from './Request Methods/postRequest';
import { ApiEndpoints } from '../store/type/API-Endpoints';
import getRequest from './Request Methods/getRequest';
import postRequestNoAuth from './Request Methods/postRequestNoAuth';
import putRequest from './Request Methods/putRequest';
import { json, redirect } from 'react-router-dom';
import getRefreshTokenRequest from './Request Methods/getRefreshTokenRequest';
import { authActions } from '../store/auth-Slice';
import deleteRequest from './Request Methods/deleteRequest';


const maxRetries = 3
let retries = 0


export const getRefreshToken = async (method) => {
    if (retries < maxRetries) {
        const uri = ApiEndpoints.refreshTokenAPI;

        const apiData = {
            refreshToken: sessionStorage.getItem("refreshToken"),
            client: sessionStorage.getItem("client")
        }


        // console.log(refreshToken);
        // console.log(client);

        //if no refreshToken then logout.
        if (apiData.refreshToken !== null || apiData.refreshToken === "") {
            redirect("/logout")
        }

        retries++

        const responseData = await getRefreshTokenRequest(uri, apiData);
        const token = responseData.token
        const refreshToken = responseData.refreshToken
        const client = sessionStorage.getItem("client");

        console.log(client);
        const newApiData = {
            token,
            client,
            refreshToken,
        }

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("refreshToken", refreshToken)
        // window.location.reload();

        return newApiData;
    }
    else {
        retries = 0
        redirect("/logout")
    }
    return null;
}

export const resendOTP = async (email, dispatch) => {

    const uri = ApiEndpoints.resendOTPAPI;

    var body = { 'email': email };

    console.log(body);
    const responseData = await postRequestNoAuth(uri, body)
    console.log(responseData.data.MessageId);

    if (responseData.data.MessageId) {
        return true
    } else {
        return false
    }
}


export const resetPortalPassword = async (apiData, email, dispatch) => {
    const uri = ApiEndpoints.resetPortalPasswordAPI;

    var body = { 'email': email };
    console.log(body);
    const response = postRequest(uri, body, apiData, dispatch)
    return response;
}

export const getDevices = async (apiData, companyName, dispatch) => {

    const uri = ApiEndpoints.getDevicesAPI + companyName
    const responseData = await getRequest(uri, apiData);
    return responseData.data
}

export const getStats = async (apiData, companyName, dispatch) => {

    const uri = ApiEndpoints.getStatsAPI + companyName;

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data
}


export const getDeviceEventLogs = async (apiData, DeviceUUID, dispatch) => {

    const uri = ApiEndpoints.getEventsAPI + DeviceUUID + "/Portal";

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData;
}

export const getSingleDeviceUsers = async (apiData, DeviceUUID, dispatch) => {

    const uri = ApiEndpoints.getUsersAPI + DeviceUUID;

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData;
}

export const getSingleDeviceData = async (apiData, DeviceUUID, dispatch) => {

    const uri = ApiEndpoints.getSingleDeviceAPI + DeviceUUID;

    const responseData = await getRequest(uri, apiData, dispatch);
    console.log(responseData)
    return responseData;
}


export const getSensorData = async (apiData) => {

    const uri = ApiEndpoints.getSensorDataAPI + 'DCC';

    const responseData = await getRequest(uri, apiData);
    return responseData;
}

export const getActivityLog = async (apiData, dispatch) => {
    const uri = ApiEndpoints.getActivityLogAPI;

    const responseData = await getRequest(uri, apiData, dispatch);
    console.log(responseData);
    return responseData.data
}

export const getInactiveDevices = async (apiData) => {
    const uri = ApiEndpoints.getInactiveDevicesAPI;

    const responseData = await getRequest(uri, apiData);
    return responseData;
}

export const getCompanyUserList = async (apiData, companyName) => {
    const uri = ApiEndpoints.getCompanyUserListAPI + companyName;

    const responseData = await getRequest(uri, apiData);
    console.log(responseData);
    const users = await responseData.data[0];
    // const usersExtraDetails = await responseData.data[1];
    // return [users, usersExtraDetails]

    return users
}

export const getUsersDevices = async (apiData, managingUserID, companyName, dispatch) => {
    const uri = ApiEndpoints.getUsersDevicesAPI + managingUserID + '/' + companyName;

    console.log(apiData);
    console.log(managingUserID);
    console.log(companyName);
    const responseData = await getRequest(uri, apiData, dispatch);
    console.log(responseData.data);
    return responseData.data;
}

//---- For Switch latch below
const generateUUID = () => {
    var d = new Date().getTime();

    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export const switchLatch = async (apiData, userID, pin, newState, DeviceUUID, pinType, deviceType, dispatch) => {
    var allPin = "no"

    if (pin == -1) {
        allPin = "all"
    }

    var uri = ApiEndpoints.switchLatchAPI

    if (deviceType != 'V') {
        uri = (pinType == 'VIRTUAL_LATCH') ? ApiEndpoints.switchLatchExternalLinkAPI : ApiEndpoints.switchLatchAPI
    }
    else {
        uri = ApiEndpoints.switchVirtualLatchAPI
    }

    console.log({
        userID,
        pin,
        newState,
        DeviceUUID,
        pinType,
        deviceType
    });

    let body = { "user": userID, "pin": parseInt(pin), "state": newState, "allPins": "", "eventID": generateUUID(), "actionType": "", "uuid": DeviceUUID }
    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData;
}

export const switchPulse = async (apiData, userID, pin, pulseLength, DeviceUUID, pinType, dispatch) => {

    const uri = (pinType == 'VIRTUAL_PULSE') ? ApiEndpoints.switchVirtualPulseAPI : ApiEndpoints.switchPulseAPI

    let body = { "user": userID, "pin": parseInt(pin), 'pulseTime': pulseLength, "eventID": generateUUID(), "uuid": DeviceUUID }


    console.log(uri, body, apiData);

    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData;
}


export const setTag = async (apiData, DeviceUUID, tag, dispatch) => {
    const uri = ApiEndpoints.setTagAPI + DeviceUUID + '/' + tag

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data.success;
}

export const changeRole = async (apiData, DeviceID, userID, role, userIDToChange, uuid, dispatch) => {
    const uri = ApiEndpoints.changeRoleAPI;

    console.log(uri);

    var body = { 'deviceID': DeviceID, 'userID': userID, 'role': role, 'userIDToChange': userIDToChange, 'uuid': uuid };



    console.log(body);
    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}


export const removeUser = async (apiData, deviceID, deviceUUID, senderUserID, userToRemoveID, dispatch) => {
    const uri = ApiEndpoints.removeUserAPI + deviceID + '/' + deviceUUID + '/' + senderUserID + '/' + userToRemoveID
    const responseData = await getRequest(uri, apiData, dispatch)

    return responseData.data.success
}

export const suspendDevice = async (apiData, suspend, deviceID, dispatch) => {
    const uri = ApiEndpoints.suspendAPI + deviceID

    var body = { 'status': suspend };
    console.log(
        uri,
        body,
        apiData
    );

    console.log(uri);
    console.log(body);
    const response = await putRequest(uri, body, apiData, dispatch)
    return response
}

export const clearDeviceLogs = async (apiData, deviceUUID, userID, dispatch) => {
    const uri = ApiEndpoints.clearLogsAPI + deviceUUID + '/' + userID

    console.log(
        uri,
        apiData
    );

    const response = await getRequest(uri, apiData, dispatch);
    console.log(response);
    return true
}

export const changeDeviceOwner = async (apiData, deviceUUID, newOwner, deviceName, dispatch) => {
    const uri = ApiEndpoints.changeOwnerAPI

    // currentDeviceUUID = ""
    // currentDeviceName = ""

    var body = { 'uuid': deviceUUID, 'name': newOwner };

    console.log(
        uri,
        body,
        apiData
    );
    const response = await postRequest(uri, body, apiData, dispatch);
    return response.data.success;
}

export const changeCamectIntegration = async (apiData, uuid, integrationType, deviceName, dispatch) => {
    const uri = ApiEndpoints.changeCamectIntegrationAPI;

    // let camectIntegationString = (integrationType == 1) ? 'normal operation' : 'per camera arming'

    var body = { 'uuid': uuid, 'integrationSetting': integrationType };

    console.log(
        uri,
        body,
        apiData
    );
    const response = await postRequest(uri, body, apiData, dispatch)
    return response;
}



export const copyIPIOSettings = async (data, toDeviceKey, dispatch) => {
    console.log(data);
    console.log(toDeviceKey);
    const { apiData, fromDeviceUUID } = data



    const uri = ApiEndpoints.copySettingsAPI;

    var body = { 'fromUUID': fromDeviceUUID, 'toUIPIOID': toDeviceKey };

    console.log(
        uri,
        body,
        apiData
    );

    if (toDeviceKey != "") {
        postRequest(uri, body, apiData, dispatch)
    }
}

export const rebootIPIODevice = async (apiData, uuid, deviceName, dispatch) => {
    const uri = ApiEndpoints.rebootIPIOAPI + uuid

    console.log(
        uri,
        apiData
    );

    getRequest(uri, apiData, dispatch)

}

export const resetIPIODevice = async (apiData, deviceUUID, userID, deviceName, dispatch) => {
    const uri = ApiEndpoints.resetDeviceAPI + deviceUUID + '/' + userID
    
    console.log(
        uri,
        apiData
    );

    if (deviceUUID != "") {
        getRequest(uri, apiData, dispatch)
    }
}

export const copyUsersToAnotherDevice = async (data, name, uipioid, dispatch) => {

    const { apiData, deviceId: deviceIDFrom, userID, DeviceName } = data;
    console.log("here");



    const uri = ApiEndpoints.copyUsersAPI;

    var body = { 'deviceIDFrom': String(deviceIDFrom), 'userID': userID, 'name': name, 'uipioid': uipioid };


    console.log(uri);

    console.log(body);

    console.log(apiData);

    const response = await postRequest(uri, body, apiData, dispatch)
    console.log(response);
}


function getAppName(companyName) {

    switch (companyName) {
        case "EyeSpyFX":
            return 'IPIO'
            break
        case "RE:SURE":
            return "RE:SURE I/O"
            break
        case "PARFAS":
            return "Parfas"
            break
        case "DCC":
            return "RE:SURE I/O"
            break
        case "SOLO":
            return "RE:SURE I/O"
            break
        case "PORTAL_SECURITY":
            return "Portal Security"
            break
        case "CPS":
            return "RE:SURE I/O"
            break
        default:
            return "IPIO"
    }
}

export const inviteUser = async (apiData, deviceID, userID, role, email, deviceName, dispatch) => {
    console.log("here");
    const uri = ApiEndpoints.inviteUserAPI
    const appName = getAppName();

    //appName
    console.log(appName)
    console.log(deviceID)
    console.log(userID)
    console.log(role)
    console.log(email)
    console.log(deviceName)

    const body = { 'deviceID': deviceID, 'userID': userID, "role": role, "email": email, "deviceName": deviceName, "appName": appName };


    console.log(uri,
        body);
    if (email != "" || email != null) {
        const responseData = await postRequest(uri, body, apiData, dispatch)
        console.log(responseData.success);
        return responseData.success
    }
}

export const PowerSearchAPI = async (apiData, query, pageSize, pageNumber, online, offline, notag, sort, dispatch) => {
    const uri = ApiEndpoints.powerSearch;
    console.log(uri);

    const body = {
        query,
        pageSize,
        pageNumber, 
        online, 
        offline, 
        notag, 
        sort
    }

    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData.data;
}

export const UserPowerSearch = async (apiData, searchTerm, dispatch) => {

    console.log(searchTerm);
    const uri = ApiEndpoints.userPowerSearch + searchTerm;
    console.log(uri);

    const responseData = await getRequest(uri, apiData, dispatch);
    console.log(responseData);
    return responseData.data;
}



export const getPagedDevicesAPI = async (apiData, pageSize, pageNumber, online, offline, notag, sort, dispatch) => {
    const uri = ApiEndpoints.pagedDevices;
    console.log(uri);

    const body = {
        pageSize,
        pageNumber, 
        online, 
        offline, 
        notag, 
        sort
    }

    console.log(apiData);
    console.log(pageSize);
    console.log(pageNumber);

    const responseData = await postRequest(uri, body, apiData, dispatch);

    console.log(responseData);
    console.log(responseData.data);
    console.log(responseData.data[0].devices);
    return responseData.data;
}




export const enableUserNotifications = async (apiData, userID, allow, dispatch) => {

    const uri = ApiEndpoints.enableUserNotificationsAPI

    var body = { 'userID': userID, 'notifications': allow };


    console.log(uri);
    console.log(body);

    const responseData = await postRequest(uri, body, apiData, dispatch)

    return responseData.data.success
}

export const switchInstallerMode = async (apiData, userID, value, dispatch) => {

    const uri = ApiEndpoints.activateInstallerModeAPI

    var body = { 'userID': userID, 'enabled': value };

    console.log(uri);
    console.log(body);

    const responseData = await postRequest(uri, body, apiData, dispatch)

    return responseData.data.success
}

export const createPortalPassword = async (email, pin, password) => {

    const uri = ApiEndpoints.createPortalPasswordAPI

    var body = { 'email': email, 'password': password, 'pin': pin };

    console.log(body);

    console.log(uri);

    const responseData = await postRequestNoAuth(uri, body)

    return responseData
}


export const getHolidaysModesAPI = async (apiData, DeviceUUID) => {
    const uri = ApiEndpoints.getHolidaysModes + DeviceUUID

    console.log(uri);
    console.log(apiData);

    const responseData = await getRequest(uri, apiData)
    console.log(responseData.data);
    return responseData.data;
}

export const createHolidayModeAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.createHolidayMode;

    console.log(body);
    console.log(uri);

    const responseData = await postRequest(uri, body, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const editHolidayModeAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.editHolidayMode;

    console.log(body);
    console.log(uri);

    const responseData = await putRequest(uri, body, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const deleteHolidayModeAPI = async (apiData, holidayModeID, dispatch) => {
    const uri = ApiEndpoints.deleteHolidayMode + holidayModeID;
    console.log(uri);

    const responseData = await deleteRequest(uri, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const getInputRules = async (apiData, DeviceUUID, dispatch) => {
    const uri = ApiEndpoints.getInputRules + DeviceUUID

    const responseData = await getRequest(uri, apiData, dispatch)

    return responseData;
}

export const setInputRuleApi = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.setInputRule


    console.log(body);
    console.log(uri);
    const responseData = await postRequest(uri, body, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const getSchedules = async (apiData, DeviceUUID, dispatch) => {
    const uri = ApiEndpoints.getSchedules + DeviceUUID;

    console.log(uri);
    console.log(apiData);
    const responseData = await getRequest(uri, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const createScheduleApi = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.getSchedules;

    console.log(uri);
    console.log(apiData);
    console.log(body);

    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}


export const updateScheduleApi = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.getSchedules;

    console.log(uri);
    console.log(apiData);
    console.log(body);

    const responseData = await putRequest(uri, body, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const deleteScheduleApi = async (apiData, scheduleID, dispatch) => {
    const uri = ApiEndpoints.getSchedules + scheduleID;

    console.log(uri);
    console.log(apiData);

    const responseData = await deleteRequest(uri, apiData, dispatch)
    return responseData;
}


export const UpdateDeviceDetails = async (apiData, body, DeviceID, dispatch) => {
    const uri = ApiEndpoints.updateDeviceDetails + DeviceID;

    console.log(apiData);
    console.log(uri);
    console.log(body);


    const responseData = await putRequest(uri, body, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const UpdateDeviceDetailsExtras = async (apiData, body, DeviceID, dispatch) => {
    const uri = ApiEndpoints.updateDeviceDetailsExtra + DeviceID;

    console.log(apiData);
    console.log(uri);
    console.log(body);


    const responseData = await putRequest(uri, body, apiData, dispatch)
    console.log(responseData);
    return responseData;
}

export const GenerateLockOTPAPI = async (apiData, body, dispatch) => {
    const uri = ApiEndpoints.generateLockOTP;

    console.log(uri);
    console.log(apiData);
    console.log(body);

    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const removeLockAPI = async (apiData, DeviceUUID, OutputPin, dispatch) => {
    console.log(OutputPin);
    const uri = ApiEndpoints.removeLock + DeviceUUID + "/" + OutputPin;

    console.log(uri);
    console.log(apiData);


    const responseData = await deleteRequest(uri, apiData, dispatch)
    return responseData;
}

export const getCamectsAPI = async (apiData, DeviceUUID, dispatch) => {

    const uri = ApiEndpoints.getCamects + DeviceUUID;

    console.log(uri);
    console.log(apiData);

    const responseData = await getRequest(uri, apiData, dispatch)
    return responseData;
}


export const addCamectAPI = async (apiData, body, dispatch) => {

    const uri = ApiEndpoints.addCamect;

    console.log(uri);
    console.log(apiData);
    console.log(body);

    const responseData = await postRequest(uri, body, apiData, dispatch);
    return responseData;
}

export const deleteCamectAPI = async (apiData, DeviceUUID, camectID, dispatch) => {

    const uri = ApiEndpoints.deleteCamect + DeviceUUID + "/" + camectID;

    console.log(uri);
    console.log(apiData);

    const responseData = await getRequest(uri, apiData, dispatch)
    return responseData;
}

export const updateCamectAPI = async (apiData, body, dispatch) => {

    const uri = ApiEndpoints.updateCamect;

    console.log(uri);
    console.log(apiData);
    console.log(body);

    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const updateCamectRelationshipAPI = async (apiData, body, dispatch) => {

    const uri = ApiEndpoints.updateCamectRelationship;

    console.log(uri);
    console.log(apiData);
    console.log(body);

    const responseData = await postRequest(uri, body, apiData, dispatch)
    console.log(responseData)
    return responseData;
}

export const getCamectCameras = async (apiData, deviceUUID, camectID) => {
    const uri = ApiEndpoints.getCamectCameras + deviceUUID + "/" + camectID

    console.log(uri)
    const responseData = await getRequest(uri, apiData)
    console.log(responseData)
    return responseData;
}

export const getSigFoxSensorsAPI = async (apiData, deviceUUID) => {
    const uri = ApiEndpoints.getSigFoxSensors + deviceUUID;

    console.log(uri);
    console.log(apiData);

    const responseData = await getRequest(uri, apiData);
    return responseData.data;
}

export const addSigfoxAPI = async (apiData, body, dispatch) => {

    const uri = ApiEndpoints.addSigfox;

    console.log(uri);
    console.log(apiData);
    console.log(body);


    const responseData = await postRequest(uri, body, apiData, dispatch)
    return responseData;
}

export const unlinkSigfoxAPI = async (apiData, deviceUUID, pin, dispatch) => {
    const uri = ApiEndpoints.unlinkSigfox + deviceUUID + "/" + pin;

    console.log(uri);
    console.log(apiData);

    const responseData = await getRequest(uri, apiData, dispatch);
    return responseData.data;
}






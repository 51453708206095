import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button1 from '../../components/UI/Button/Button1';
import { getDevices, getPagedDevicesAPI, PowerSearch } from '../../Api Methods/Api';

import classes from "./GetAllDevices.module.css";
import DataManager from '../../hooks/DataManager';

const GetAllPagedDevices = ({ searchQuery, setIsLoading, handleFilterLogic, categoryFilter, sortBy, setSearchQuery, setIsGetAllDevicesPressed, isGetAllDevicesPressed, filteredResults, setTotalPages, setFilteredResults, setMaxPagedFetches, maxPagedFetches, setPageFetchedIndex, pageFetchedIndex, pageSize, pageNumber, totalDevices, setTotalDevices, getAllDevices }) => {
    const dataManager = new DataManager();

    pageSize = dataManager.getItem('pageSize');
    pageNumber = pageFetchedIndex;

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const companyName = useSelector(state => state.auth.companyName);

    const ApiData = {
        token,
        client
    }

    const GetAllDevicesTimer = useRef();

    const GetAllDevices = async (GetAllDevicesTimer) => {        
        GetAllDevicesTimer.current = Date.now();
        const triggerTime = GetAllDevicesTimer.current;
        const online = dataManager.getItem('online');
        const offline = dataManager.getItem('offline');
        const noTag = dataManager.getItem('noTag');
        const sort = dataManager.getItem('sort');
        const responseData = await getPagedDevicesAPI(ApiData, pageSize, pageNumber, online, offline, noTag, sort, dispatch);
        const totalDevices = responseData[0].totalDevices["COUNT(*)"];
        const Sites = responseData;
        setTotalPages(Math.ceil(totalDevices/pageSize));
        setTotalDevices(totalDevices);

        if (GetAllDevicesTimer.current == triggerTime){
            console.log("HERE")
            //setLocalCategoryFilters(categoryFilter);
            //setLocalSortBy(sortBy);
            setIsLoading(false);
            setGetAllDevicesButtonText("All Sites");
            handleFilterLogic(Sites, companyName, categoryFilter, sortBy);
        }
    }

    useEffect(() => {

        //if (!dataManager.getItem('filteredResults')?.length > 0){
            setCategoryFilters()
            setSort()
        //}
    

        if (isGetAllDevicesPressed && dataManager.getItem('searchQuery') === ""){
            GetAllDevices(GetAllDevicesTimer)
        }
    }, [pageNumber, pageSize, isGetAllDevicesPressed, getAllDevices, categoryFilter, sortBy])

    function setCategoryFilters(){
        dataManager.setItem('online', 0);
        dataManager.setItem('offline', 0);
        dataManager.setItem('noTag', 0);

        categoryFilter.forEach((filter) => {
            switch (filter) {
                case "Online": {
                    console.log("here");
                    dataManager.setItem('online', 1);
                    break;
                }
                case "Offline": {
                    console.log("here");
                    dataManager.setItem('offline', 1);
                    break;
                }
                case "No Tag": {
                    console.log("here");
                    dataManager.setItem('noTag', 1);
                    break;
                }
                case "all": {
                    console.log("here");
                    dataManager.setItem('online', 0);
                    dataManager.setItem('offline', 0);
                    dataManager.setItem('noTag', 0);
                    break;
                }
                default: {
                    dataManager.setItem('online', 0);
                    dataManager.setItem('offline', 0);
                    dataManager.setItem('noTag', 0);
                    break;
                }
            }
        });
    }

    function setSort(){
        dataManager.setItem('sort', -1)
        console.log(sortBy)
        switch (sortBy[0]){
            case 'Tag - Asc': {
                console.log("here");
                dataManager.setItem('sort', 0);
                break;
            } 
            case "Tag - Desc": {
                console.log("here");
                dataManager.setItem('sort', 1);
                break;
            }
            case "Firmware - Asc": {
                console.log("here");
                dataManager.setItem('sort', 2);
                break;
            }
            case "Firmware - Desc": {
                console.log("here");
                dataManager.setItem('sort', 3);
                break;
            }
            case "User Count - Asc": {
                console.log("here");
                dataManager.setItem('sort', 4);
                break;
            }
            case "User Count - Desc": {
                console.log("here");
                dataManager.setItem('sort', 5);
                break;
            }
            default : {
                console.log("here");
                dataManager.setItem('sort', -1);
                break;
            }
        }
    }
    
    const [getAllDevicesButtonText, setGetAllDevicesButtonText] = useState("All Sites");

    function arraysAreIdentical(arr1, arr2) {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((value, index) => value === arr2[index]);
    }

    const HandleSetGetAllDevices = () => {
        console.log("here");
        setIsGetAllDevicesPressed(true)
        dataManager.setItem('allSitesPressed', true);
        setPageFetchedIndex(1);
        setFilteredResults([]);
        setSearchQuery("");
        GetAllDevices(GetAllDevicesTimer)
    }

    return (
        <Button1 style={{ height: "50px", fontSize: "16px", minWidth: "120px" }} type={"button"} className={classes.button} onClick={HandleSetGetAllDevices}>{getAllDevicesButtonText}</Button1>
    )
};

export default GetAllPagedDevices;

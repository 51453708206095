import React, { useMemo, useEffect, Suspense, useState } from 'react'

import { DateTime } from 'luxon';

import classes2 from "./UsersTableLayout.module.css";
import classes from "./TableLayout.module.css";
import Card from "./Card";
import Table from './Table/Table';
import TableButton from './Table/TableButton/TableButton';
import fiiller_rows from './Table/MOCK_DATA_3.json'
import { Await, useLoaderData } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import BasicTable from './Table/Table';
import { getActivityLog, getInactiveDevices } from '../../Api Methods/Api';
import UseTimeDateFormat from '../hooks/UseTimeDateFormat';
import { useDispatch } from 'react-redux';

const DeviceActivityTableLayout = ({ HanldeshowAddUserForm, filtering, HandleTableFiltering, apiData, companyName }) => {

  // ---------------------------- loader data ---------------------------------
  const { deviceActivity } = useLoaderData();
  console.log(deviceActivity);
  useEffect(() => {
    if (deviceActivity.isError) {
      console.log(deviceActivity)
      return deviceActivity.message;
    }
  }, [deviceActivity])


  const [deviceActivityEvents, setDeviceActivityEvents] = useState(deviceActivity)
  //---------------------------------------------------------------------------


   //------------------------------- responsible for updating the Activity Page data every 1 mintue ------------------------------------------------------
   const dispatch = useDispatch();
   useEffect(() => {

    // set initial portalActivityEvents on mount
    setDeviceActivityEvents(deviceActivity);

    console.log(deviceActivity)

    const HandleDeviceEvents= async () => {
        const data = await getInactiveDevices(apiData, dispatch)
        console.log(data)
        setDeviceActivityEvents(data)
    }

    // Set up a timer to fetch data every 1 minute
    const timer1 = setInterval(() => {

      HandleDeviceEvents();
    }, 60000);
    return () => clearInterval(timer1);
}, []);
//---------------------------------------------------------------------------------------------------------------------
  
  const tablecolumns = [
    {
      header: 'Tag',
      accessorKey: 'tag',
    },
    {
      header: 'UIPIOID',
      accessorKey: 'key',
    },
    {
        header: 'Status',
        accessorKey: 'online',
        cell: (row) => {
            console.log(row.row.original.online)

            if (row.row.original.online === "YES"){
                return (
                    <>
                        <p>Online</p>
                    </>
                )
            } else {
                return (
                    <>
                        <p>Offline</p>
                    </>
                )
            }
        }
    },
    {
        header: 'Last Interaction',
        accessorKey: 'lastEvent',
        cell: (row) => {
            const { date, time } = UseTimeDateFormat(row.row.original.lastEvent)
            console.log(row.row.original.lastEvent)
            
            if (row.row.original.lastEvent == null){
                return (
                    <>
                      <p>Before Jan 1st, 2024</p>
                    </>
                  )
                } else {
            return (
              <>
                <p>{date + ", " + time}</p>
              </>
            )
          }
        }
    },
    {
        header: 'User Count',
        accessorKey: 'userCount'
    },
    {
        header: 'Owned By',
        accessorKey: 'name',
    }
  ];




  const deviceActivityData = deviceActivityEvents
  console.log(deviceActivityEvents)

  return (
    <div className={`${classes.table} ${classes.center} `}>
      <Card card2={classes.card2}>

        <div className={classes["tableCardLayoutUI-wrapper"]}>
          <div className={classes["top-wrapper"]}>
            <h1>Site Activity</h1>
            <p>To sort, click on the column name</p>
            <small>*Before Jan 1st, 2024 - Events that occured before this are no longer accessible</small>
            <br></br>
          </div>
          <Suspense fallback={
            <div className={classes["loadingSpinner-container"]}>
              <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
            </div>
          }>
            <Await resolve={deviceActivityData}>
              {(loadedtableData) => {
                console.log(loadedtableData); 
                const extractedData = loadedtableData.data;
                  // loadedtableData.reverse()
                  return (
                    <BasicTable 
                        data={extractedData} 
                        columns={tablecolumns} 
                        filtering={filtering} 
                        rowClick={false} 
                        ShowPagination={true} 
                        PageSize={50} 
                        paginationOptions={[25, 50, 100]} 
                        showFilterButton={true} 
                        HandleTableFiltering={HandleTableFiltering} 
                    />)
                //return (<BasicTable data={loadedtableData} columns={tablecolumns} filtering={filtering} rowClick={false} ShowPagination={true} PageSize={50} paginationOptions={[25, 50, 100]} showFilterButton={true} HandleTableFiltering={HandleTableFiltering} />)
              }}
            </Await>
          </Suspense>
        </div>
      </Card>
    </div>
  )
}

export default DeviceActivityTableLayout;